import React from 'react'
import styled from 'styled-components'
import {WHITE, DARK_GREEN, LIGHT_GREEN} from '../../constants/Colors'

import amvhb from '../../images/AMVHB-logo.png';
import logoEbramec from '../../images/logo-ebramec-rodape.png';
import miati from '../../images/logo-miati-design.png';
import FacebookSquareBrands from '../../images/icons/facebook-square-brands';
import InstagramBrand from '../../images/icons/instagram-brands';
import WhatsAppBrand from '../../images/icons/whatsapp-brands';
import Text from '../typograph/Text';

const FooterContacts = styled.div`
    background-color:${DARK_GREEN};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-right: 0px;
    padding-bottom: 32px;

    @media (max-width: 1073px) {
        flex-wrap: wrap;
      }

      @media (max-width: 695px) {
        justify-content: flex-start;
    }

    @media (max-width: 340px) {
        padding-right: 0px;
    }
`;

const Partners = styled.div`
    background-color:${WHITE};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 17px 25px 17px rgba(148,146,148,1);

    @media (max-width: 900px) {
        flex-wrap: wrap;
        padding-top: 20px;
        padding-bottom: 20px;
      }
`;

const Partner = styled.div`
    background-color:${WHITE};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 499px) {
        flex-wrap: wrap;
      }
`;

const FooterItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-right: 20px;
    

    & .footerItemHeaderText{
        margin-top: 24px;
        width: 100%;

    }

    &  .footerItemHeader{
        font-size: 20px;  
        margin-bottom: 0;
        padding-bottom: 0;
        color: #fff;
        display: flex;
        align-items: center;

    }

    &.lastFooterItemHeader{
        padding-top: 28px;
        display: flex;
        max-width: 300px;
        flex-wrap: wrap;
        justify-content: flex-start;

        @media (max-width: 1050px) {
            max-width: 90%;
            width: 90%;
            justify-content: space-between;
        }

        @media (max-width: 800px) {
            max-width: 100%;
            display: block;
            margin-left: calc(50% - 200px);

        }

        @media (max-width: 413px) {
            max-width: 100%;
            display: block;
            margin-left: 10px;
 
        }

    }

    @media (max-width: 1249px) {
        font-size: 14px;
    }

    @media (max-width: 800px) {
        font-size: 16px;
        width: 400px;
        justify-content: center;
        margin-bottom: 0;
        padding-bottom:0;
        margin: 0 auto;
    }

    @media (max-width: 413px) {
        margin-left: 10px;

    }

`;

const Footer = () => (
    <footer> 
    <Partners>
         <Partner>
            <img src={amvhb} alt='Logo AMVHB'/>
            <Text type='p' style={{maxWidth:'300px', padding: '0 12px'}}>
                Nossos cursos são chancelados pela <b>AMVHB</b> (Associação de Médicos 
                Veterinários Homeopatas do Brasil).
            </Text>
         </Partner>
     </Partners>
     <FooterContacts>
         <FooterItem>
            <Text type='p' reversed={true} className='footerItemHeaderText'>
            <h3 className='footerItemHeader'>Unidade S. Caetano Polo Ebramec</h3>
            Rua São Paulo, 1.096 - São Caetano do Sul/SP
            <br/>Estacionamento em horário comercial ao lado do local
                <br/><a
                href="https://www.google.com/maps/place/R.+S%C3%A3o+Paulo,+1096+-+Santa+Paula,+S%C3%A3o+Caetano+do+Sul+-+SP,+09541-100/@-23.6221246,-46.572834,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce5cc1c3baf93b:0xbfb2ebfc2355937a!8m2!3d-23.6221246!4d-46.57064"
                target="_blank"
                rel="external noopener noreferrer"
                style={{color:LIGHT_GREEN, cursor:'pointer', textDecoration:'none', fontWeight:'bold'}}
                >(veja no mapa)</a><br /><br />
                <b className='footerItemHeaderText' style={{fontWeigth:'bold', whiteSpace: 'nowrap'}}>
                11 2376.4447 / 
                <a href='https://api.whatsapp.com/send?phone=5511991760079' style={{color:LIGHT_GREEN, cursor:'pointer', textDecoration:'none', fontWeight:'bold'}} target='_blank' rel='external noopener noreferrer'> 11 99176.0079 <WhatsAppBrand /></a><br />
                <a href='https://api.whatsapp.com/send?phone=5511981868532' style={{color:LIGHT_GREEN, cursor:'pointer', textDecoration:'none', fontWeight:'bold'}} target='_blank' rel='external noopener noreferrer'>11 98186.8532 </a> ou 
                <a href='https://api.whatsapp.com/send?phone=5511996315666' style={{color:LIGHT_GREEN, cursor:'pointer', textDecoration:'none', fontWeight:'bold'}} target='_blank' rel='external noopener noreferrer'> 11 99631.5666 <WhatsAppBrand /></a>
                </b>
            </Text>    
         </FooterItem>
         <FooterItem className='lastFooterItemHeader'>
            <div>
                <h3 className='footerItemHeader'>E-mail</h3>
                <p style={{padding:0, marginTop:8}}>
                    <a href='mailto:secretaria@hdscience.com.br'
                        style={{color:LIGHT_GREEN, cursor:'pointer', textDecoration:'none', fontWeight:'bold'}}>secretaria@hdscience.com.br</a>
                </p>
            </div>
            <div>
                <h3 className='footerItemHeader' style={{padding:'10px 0 20px', margin:0}}>Redes Sociais 
                <a href='https://www.instagram.com/hdscienceveterinaria/' target='_blank' rel="external noopener noreferrer">
                    <InstagramBrand style={{paddingLeft:10}}/>
                </a>
                <a href='https://www.facebook.com/hdscience/' target='_blank' rel="external noopener noreferrer">
                <FacebookSquareBrands />
                </a>
                </h3>
            </div>
            <br />
            <div>
            <a href='https://ebramec.edu.br/' target='_blank' rel='external noopener noreferrer'>
                    <img src={logoEbramec} alt='EBRAMEC'/>
                </a>
                <a href='https://www.miati.com.br/'  target='_blank' rel='external noopener noreferrer'>
                    <img src={miati} style={{marginLeft:16}} alt='Miati Design e Tecnologia'/>
                </a>
            </div>
            
         </FooterItem>
     </FooterContacts>
    </footer>
)

export default Footer;