import React from 'react'
import styled from 'styled-components'
import smallLogo from '../../images/logo-flat-site.png';
import fullLogo from '../../images/logo-completo-site.png';
import { PRIMARY, WHITE, DARK_GREEN, LIGHT_GREEN } from '../../constants/Colors';
import MenuButton from '../buttons/MenuButton';
import MenuLink from '../links/MenuLink';
import GatsbyLink from 'gatsby-link';

const HeaderContainer = styled.div`
  width: 100%;
  height: 64px;
  background-color: ${PRIMARY};
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  z-index: 20000;
`

const LogoContainer = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  z-index:10;

  & img {
    position: absolute;
    left: 0;
    top: 0;
    transition: opacity 0.6s ease-in-out;
    margin: 0;
    border: none;

    @media (max-width: 945px){
      left: calc(50% - 105px);

      &.noMobo{
        display: none;
      }
    }
  }

`

const MenuContainer = styled.div`
  position: relative;
  margin: 0 auto;
  left: auto;
  width: 100%;
  max-width: 1500px;
  text-align: right;

  @media screen and (min-width: 946px){
    height: 64px;
  }
`

const Nav = styled.nav`
  display: flex;
  align-itens: flex-end;
  justify-content: flex-end;
  padding-left: 230px;
  padding-right: 20px;
  background-color: ${PRIMARY};
  z-index:30000;
  
  @media screen and (max-width: 945px){
    overflow: hidden;
    display:block;
    transition: height 0.6s ease-in-out;
    height: 0;
    position: absolute;
    width: 100%;
    text-align: left;
    margin-top: 64px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    border-top: ${WHITE} solid 5px;

    & *:nth-child(even){
      background-color: #04928e33;
    }
  }
  
`

const isActive = ({ isPartiallyCurrent, style }) => {
  return isPartiallyCurrent ? {style: {
    ...style, 
    color: DARK_GREEN,
    backgroundColor: LIGHT_GREEN,
    borderRadius: '5px',
    boxShadow: '0px 2px 5px 0 rgba(54, 45, 43, 0.16)'}}: {style: style}
}

class Header extends React.PureComponent{

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if(window.scrollY > 80 && this.completeImage){
      this.completeImage.style.opacity = 0;
      this.completeImage.style.height = 0;
      this.smallImage.style.opacity = 1;
    }

    if(window.scrollY <= 80 && this.completeImage && window.innerWidth > 945){
      this.completeImage.style.opacity = 1;
      this.completeImage.style.height = 'auto';
      this.smallImage.style.opacity = 0;
    }
  }

  whenMenuActive = () => {
    this.nav.style.height = '252px';
    this.smallImage.style.opacity = 0;
    this.completeImage.style.opacity = 0;
  }

  whenMenuInactive = () => {
    this.nav.style.height = '0px';
    this.smallImage.style.opacity = 1;
    this.handleScroll();
  }

  render() {
    return (
      <HeaderContainer>
        <MenuButton 
          position='absolute' 
          top='0' 
          right='0' 
          whenActive={this.whenMenuActive}
          whenInactive={this.whenMenuInactive}
        />
        <MenuContainer>
          <LogoContainer>
            <GatsbyLink to="/">
              <img ref={(smallImage) => this.smallImage = smallImage} src={smallLogo} alt="HD Science - Logo reduzido"/>
              <img className='noMobo' ref={(completeImage) => this.completeImage = completeImage} src={fullLogo} alt="HD Science - Logo completo"/>
            </GatsbyLink>
          </LogoContainer>
          <Nav innerRef={(nav) => {this.nav = nav}}>
              <MenuLink to="/sobre-nos/">SOBRE NÓS</MenuLink>
              <MenuLink to="/profissionais/">PROFISSIONAIS</MenuLink>
              <MenuLink to="/cursos-e-minicursos/" getProps={isActive}>CURSOS & MINI-CURSOS</MenuLink>
              <MenuLink to="/homeopatia-a-ciencia-do-terceiro-milenio/">HOMEOPATIA: CIÊNCIA DO 3º MILÊNIO</MenuLink>
              <MenuLink to="/contato/">CONTATO</MenuLink>
          </Nav>
        </MenuContainer>
      </HeaderContainer>
    )
  }
} 

export default Header
