import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Header from './Header'
import './layout.css'
import Footer from './Footer';

const Layout = ({ children, title, meta }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey="6LeyibYZAAAAAPMOVJi9ZLcoXIH3Pc_B5FtJDNtV"
    language="pt-BR"
  >
    <StaticQuery  
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
          <div style={{minHeight:'100%'}}>
            <Helmet
              title={title || data.site.siteMetadata.title}
              meta={meta}
            >
              <script>
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-MKMZ2BT9');`}
              </script>
              <script>
                {`!function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '400421507741277');
                  fbq('track', 'PageView');`}
              </script>
              <html lang="pt-BR" />
            </Helmet>
            <Header siteTitle={title || data.site.siteMetadata.title} />
            <div
              style={{
                margin: '80px auto 0 auto',
                padding: 0,
              }}
            >
              {children}
            </div>
            <Footer />
            <noscript>
              <img height="1" width="1" alt="facebook pixel" style={{display:'none'}} src="https://www.facebook.com/tr?id=400421507741277&ev=PageView&noscript=1"/>
              <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MKMZ2BT9" height={0} width={0} style={{display: 'none', visibility: 'hidden'}}></iframe>
            </noscript>
          </div>
      )}
    />
  </GoogleReCaptchaProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
