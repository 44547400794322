import React from 'react'
import Link from 'gatsby-link'
import { WHITE, LIGHT_GREEN, DARK_GREEN } from '../../constants/Colors';
import { FONT_FAMILY_2 } from '../../constants/FontFamily';
import styled from 'styled-components'

const StyledLink = styled(Link)`
    font-family: ${FONT_FAMILY_2};
    font-size: 16px;
    font-weight: bold;
    line-height: 36px;
    color: ${WHITE};
    text-decoration: none;
    margin-top: 15px;
    padding: 0px 13px;

    @media screen and (max-width: 1200px){
        font-size:14px;
    }

    @media screen and (max-width: 1100px){
        font-size:12px;
    }

    @media screen and (max-width: 997px){
        font-size:11px;
    }

    @media screen and (max-width: 945px){
        display: block;
        line-height: 50px;
        margin-top: 0px;
    }
`;

const MenuLink = ({to, children, ...props}) => (
    <StyledLink {...props} to={to} activeStyle={{
        color: DARK_GREEN,
        backgroundColor: LIGHT_GREEN,
        borderRadius: '5px',
        boxShadow: '0px 2px 5px 0 rgba(54, 45, 43, 0.16)'
    }} >{children}</StyledLink>
)

export default MenuLink